.gallary_content {
  /* color: var(--secondary-colour); */
  box-shadow: inset 0px 1px 3px 1px hsl(0 0% 20%);
  border-radius: 1em;
  padding: 1em;
  padding-block: 2em;
  margin: 0px;
  margin-top:1em;
  margin-bottom:1em;
  display: grid;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.gallary_height_buffer {
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 21em;
  height: 21em;
  min-height: 23em;
  min-width: 23em;
}

.gallary_item {
  position:relative;
  z-index: 1;
  box-shadow: inset 0px 1px 3px 1px hsl(0 0% 20%);
  border-radius: 1em;
  background-color: var(--primary-colour);
  overflow: hidden;
  padding: 0px;
}

.gallary_item_img_link {
  display:inline-flex;
  border-radius: 1em;
  background-image: linear-gradient(hsla(0, 0%, 0%, 0%), hsla(0, 0%, 0%, 0%), hsla(0, 0%, 0%, 50%));
  box-shadow: 0px 1px 3px 1px hsl(0 0% 20%);
  position: relative;
  margin: 0;
  padding: 0.8em;
  /* max-width: 20em; */
  /* max-height: 20em; */
  /* width: 20em; */
  /* height: 20em; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.gallary_item_img_link:hover {
  background-image: linear-gradient(hsla(0, 0%, 0%, 0%), hsla(0, 0%, 0%, 0%), hsla(0, 0%, 0%, 70%));
}

.gallary_item_img {
  background-color: red;
  z-index: -1;
  display: block;
  object-fit: contain;
  position: relative;
  max-width: 20em;
  max-height: 20em;
  /* max-width: 100%; */
  /* max-height: 100%; */
  height: 100%;
  width: auto;
  background-color: white;

  transition: max-height 100ms ease-out,
  max-width 100ms ease-out;
}

.gallary_item_img_link:hover img {
  max-height: 21em;
  max-width: 21em;
}

.gallary_item_info {
  font-size: 1.2em;
  color: var(--text-light);
  position: absolute;
  padding-inline: 1em;
  bottom: 8%;
  /* bottom: 0px; */
  width: 100%;
  display:flex;
  justify-content: space-between;
  text-decoration: none;
  /* background-image: linear-gradient(hsla(0, 0%, 0%, 0%),  hsla(0, 0%, 0%, 70%),hsla(0, 0%, 0%, 70%), hsla(0, 0%, 0%, 0%)); */
  /* background-color: hsla( 0, 0%, 0%, 50%); */
}

.gallary_item_section {
  align-self: flex-end;
  display: block;
  color: inherit;
  text-shadow: 1px 1px hsl(0 0% 30%);
}

.gallary_item_name {
  text-decoration: inherit;
  align-self: flex-end;
  margin: 0px;
  transition: color 200ms;
  color: inherit;
  text-shadow: 1px 1px hsl(0 0% 10%);
}

.gallary_item_info a:hover {
  color: hsl(21 6% 65%);
}

.gallary_tital {
  font-family: 'Source Serif Pro', serif;
  font-size: 2.5em;
  font-weight: bolder;
  color: var(--secondary-colour);
  margin: 0px;
  text-align: center;
  width: 80%;
  margin-inline: auto;
}

.gallary_header_text {
  text-align: center;
  width: 80%;
  margin-inline: auto;
}

.pagination_buttons {
  display: flex;
  justify-content: center;
}

.pagination_content {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center
}

.pagination_button {
  padding-inline: 0.4em;
  text-align: center;
  font-size: 1.1em;
  margin: 0.2em;
  background-color: var(--primary-colour);
  color: var(--secondary-colour);
  border: none;
  appearance: none;
  text-shadow: none;
}

.pagination_button_selected {
  box-shadow:inset 0px 0px 1px 1px hsl(0 0% 10%);
  text-shadow: 1px 1px hsl(0 0% 10%);
  background-color: var(--secondary-colour);
  color: var(--primary-colour);
  border-radius: 0.2em;
  padding-inline: 0.4em;
}

.pagination_button:hover {
  background-color: var(--secondary-colour);
  color: var(--primary-colour);
  border-radius: 0.2em;
}

@media only screen and (max-width: 430px) {
  .gallary_content {
    border-radius: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 360px) {
  .gallary_content {
    padding: 0px;
    padding-top: 1em;
    padding-bottom: 1em;
  }

 .gallary_item {
   max-width: 100%;
   width: 100%;
   border-radius: 0px;
 }

 .gallary_item:hover {
   max-width: 100%;
   width: 100%;
 }

 .gallary_height_buffer {
   max-width: 100%;
   width: 100%;
 }

 .gallary_height_buffer:hover {
   max-width: 100%;
   width: 100%;
 }

 .gallary_item_img_link {
   border-radius: 0em;
 }
}

