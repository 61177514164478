.address_collect {
  background-color: var(--secondary-colour);
  color: var(--primary-colour);
  border-radius: 1em;
  padding: 1em;
  min-height: 28em;
  box-sizing: border-box;
  min-width: 70%;
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
  'header header header header'
  'name name email email'
  'address address address address'
  'postal postal city city'
  'provence provence country country'
  'spacer button button spacer2';
}

@media only screen and (max-width: 480px) {
  .address_collect {
    grid-template-columns: 1fr;
    grid-template-areas:
    'header'
    'name'
    'email'
    'address'
    'postal'
    'city'
    'provence'
    'country'
    'button';
    justify-content: center;
    align-items: center;
  }
}

.address_spacer {
  grid-area: spacer;
}

.address_spacer2 {
  grid-area: spacer2;
}

.address_header {
  grid-area: header;
  margin-bottom: 1em;
}

.address_header h1 {
  font-family: 'Source Serif Pro', serif;
}

.address_button{
  grid-area: button;
  margin-top: 1.5em;
}

.address_button button{
  grid-area: button;
  min-width: 100%;
  min-height: 2.2em;
  margin-top: 1em;
}

.address_collect label {
  margin-bottom: 0.2em;
  margin-top: 0.2em;
  display: block;
}

.address_collect input {
  box-sizing: border-box;
  width: auto;
  min-width: 100%;
  min-height: 2em;
}

.address_name {
  grid-area: name;
}

.address_email {
  grid-area: email;
}

.address_street_address {
  grid-area: address;
}

.address_postal {
  grid-area: postal;
}

.address_city {
  grid-area: city;
}

.address_provence {
  grid-area: provence;
}

.address_country {
  grid-area: country;
}
