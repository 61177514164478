.print_page {
  position: relative;

  max-width: 60em;
  padding: 1em;
  /* padding-bottom: 0em; */
  max-height: 40em;
  margin: 1em;
  gap: 1em;

  background-color: var(--secondary-colour);
  border-radius:1em;
  box-shadow: 0px 0px 3px 1px hsl(0 0% 10%);

  display: grid;
  grid-template-rows: 1fr 10fr 1fr;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-areas:
  'img img tital'
  'img img info'
  'img img checkout';
  justify-items: center;
  align-items: center;
}

.print_page_img_container {
  /* background-color: var(--primary-colour); */
  border-radius: 1em;
  margin: 1em;
  grid-area: img;
  flex-shrink: 0;
  /* max-width: 33em; */
  /* max-height: 33em; */
  width: 100%;
  height: 100%;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 0px 3px 1px hsl(0, 0%, 10%);
}

.print_page_img_container img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  height: auto;
}

.print_page_checkout {
  min-width: 15em;
  min-height: 3em;
  max-width: 15em;
  max-height: 3em;
}

.print_info {
  background-color: var(--secondary-colour);
  color: var(--primary-colour);
  border-radius: 1em;
  padding: 1em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 1;
}

.print_info_tital {
  justify-self: flex-start;
  color: var(--primary-colour);
  text-shadow: 1px 1px 1px hsl(0 0% 15%);
  grid-area: tital;
}

.print_info_text {
  grid-area: info;
  box-shadow: inset 0px 0px 3px 1px hsl(0, 0%, 20%);
  max-height:100%;
  min-height: 100%;
  min-width: 100%;
  background-color: var(--primary-colour);
  color: var(--secondary-colour);
  text-shadow: 1px 1px 1px hsl(0 0% 40%);
  border-radius: 1em;
  padding: 1em;
  overflow-y: scroll;
}

.checkout_info {
  width: 20em;
  margin: 1em;
  grid-area: checkout;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 962px) {
  .print_page {
    display:block;
    overflow-y: scroll;
  }

  .print_page_img_container {
    margin-inline: auto;
  }

  .checkout_info {
    width: auto;
  }

  .print_info_text {
    max-width: 80%;
    max-height: none;
  }
}

