*::-webkit-scrollbar {
  background-color: hsla(0, 0%, 0%, 0%);
  width: 0.3em;
  max-width: 0.3em;
}

*::-webkit-scrollbar-track {
  margin-block: 1em;
}

*::-webkit-scrollbar-thumb {
  background-color: hsla(21, 6%, 21%, 60%);
  border-radius: 100vh;
}

* {
  scrollbar-width: thin;
  scrollbar-color: hsla(21, 6%, 21%, 60%) hsla(0, 0%, 0%, 0%);
  box-sizing: border-box;
}


:root {
  --header-hight: 3.5em;
  --footer-hight: 6em;
  --primary-colour: hsla(21, 6%, 57%, 1);
  --primary-light: hsla(21, 6%, 68%, 1);
  --text-light: hsla(21, 6%, 86%, 1);
  --primary-light-trasparent: hsla(21, 6%, 68%, 25%);
  --secondary-colour: hsla(21, 6%, 21%, 1);
  --secondary-dark: hsla(21, 6%, 15%, 1);
  --primary-secondary-blend: hsla(21, 6%, 35%, 1);
  --background-colour: hsla(21, 6%, 10%, 1);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  background-color: var(--background-colour);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  margin: 0px;
  padding: 2em;
  overflow:auto;
  box-sizing: border-box;
  background-color: var(--primary-colour);
  max-width: 80em;
  min-height: calc((100vh - var(--header-hight)) - var(--footer-hight));
  /* width: 100vw; */
  /* width: 100%; */
  margin-left: auto;
  margin-right: auto;
  box-shadow: inset 0px 2px 4px 1px hsl(0 0% 40%);
}

@media only screen and (max-width: 430px) {
  .content {
    width: 100vw;
    margin-inline: 0px;
    padding-inline: 0px;
  }
}

.landingpage-content {
  padding: 0;
  margin: -2em;
  margin-bottom: 3em;
  background-image: url("../public/assets/images/hands_of_change_no_center.png");
  min-height: calc(100vh - var(--header-hight));
  background-repeat: no-repeat;
  background-size: calc(1400px * 1.2);
  background-attachment: local;
  background-position: 50% 50%;
  display:flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 0px 5px;
}

.landingpage-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 1em;
  border-radius: 100%;
  border-style: solid;
  border-width: 3em;
  border-color: black;
  transition: border-color 200ms,
   min-width 200ms,
   min-height 200ms,
   font-size 200ms;
  min-width: 20rem;
  min-height: 20rem;
  margin: auto;

  text-decoration: none;
}

.landingpage-button span {
  font-size: 3.5em;
  font-weight: bold;
  font-family: 'Source Serif Pro', serif;
  color: var(--secondary-colour);
}

.landingpage-button:hover {
  border-color: hsl(0 0% 15%);
  font-size: 1.05em;

  min-width: 21rem;
  min-height: 21rem;
}

.landingpage-button a {
  text-align: center;
  text-justify: center;
}

.about {
  margin-bottom: 3em;
}

.cover {
  /* float: center; */
  position: fixed;
  background-color: hsla(0, 0%, 0%, 80%);
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  /* overflow-y: scroll; */
}

.focus {
  max-height: 70%;
}
