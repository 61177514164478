
.footer {
  width: 100%;
  max-width: 100%;
  min-height: var(--footer-hight);
  background-color: var(--secondary-colour);

  padding-top: 2em;
  margin: auto;
  min-width: 70%;
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.footer p {
  margin-top: 0;
}


.footer-list li {
  display: inline;
}

.footer-list li a {
  text-decoration: none;
  color: var(--primary-colour);
  font-size: 1.1em;
}

