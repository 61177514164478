.checkout {
  display: grid;
  grid-template-columns: 3fr 1fr;
  /* grid-template-rows: minmax(10em, auto); */
  grid-template-areas: 'collect summary';
  justify-content: space-between;
  gap: 0.8em;
}

@media only screen and (max-width: 775px) {
  .checkout {
    grid-template-columns: 1fr;
    grid-template-areas:
    'summary'
    'collect';
  }
}

@media only screen and (max-width: 480px) {
  .checkout {
    grid-template-columns: 1fr;
    grid-template-areas:
    'summary'
    'collect';
  }
}

.data_collect {
  grid-area: collect;
}

.checkout {
  margin-top: 0px;
  margin-bottom: 0px;
}

.summary {
  grid-area: summary;
  min-height: 30em;
  min-width: 17em;
  background-color: var(--secondary-colour);
  color: var(--primary-colour);
  padding: 0.5em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summary h1 {
  font-family: 'Source Serif Pro', serif;
}

.summary h2 {
  font-size: 1em;
  display: inline;
}

.orders_list {
  min-height: 50%;
}

.summary_details{
  font-size: 0.8em;
}

.summary_breaks {
  color: var(--primary-secondary-blend);
}

.summary_cart_list {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.summary_cart_item {
  max-height: 2em;
  display:flex;
  align-items: center;
  gap: 0.5em;
}

.summary_cart_img {
  max-width: 2em;
  max-height: 2em;
  width: 2em;
  height: 2em;
  object-fit: contain;
  background-color: var(--primary-colour);
  border-radius: 0.2em;
}

.summary_cart_text {
  display:flex;
  justify-content: space-between;
  width: 100%;
}
