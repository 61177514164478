.contact-content {
  padding: 1em;
  margin: 0px;
}

.contact {
  border-radius: 1em;
  padding: 1em;
  background-color: var(--secondary-colour);
  margin: 1em;
  box-shadow: inset 0px 0px 3px 3px hsla(0 0% 20%);
  color: var(--primary-colour);
  font-size: 1.1em;
  text-align: center;
}
