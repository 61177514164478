.rate_select {
  margin-bottom: 1em;
}

.shipping_rates {
  margin-bottom: 1em;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  color: var(--primary-colour);
  background-color: var(--secondary-colour);
  border-radius: 1em;
  padding-left: 5em;
  padding-right: 5em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.shipping_rates h1 {
  margin-top: 0px;
  margin-bottom: 0.5em;
}

.checkout_address {
  margin: 0px;
}

.checkout_address ul{
  padding: 0px;
}

.checkout_address li{
  margin: 0px;
  list-style: none;
}

.rate_address_list_label {
  display: inline-block;
  width: 6em;
}
