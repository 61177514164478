.commisions_content {
  background-color: var(--secondary-colour);
  border-radius: 1em;
  padding: 1em;
  padding-top: 4em;
  padding-bottom: 4em;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
}

.commisions-blerb {
  margin: 1em;
  padding: 1em;
  max-width: 70;
}

.commisions_item {
  max-width: 20em;
  max-height: 20em;
  min-width: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.commisions_item_info {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  justify-content: space-between;
}

.commisions_item_info a {
  text-decoration: none;
  transition: color 200ms
}
.commisions_item_info a:hover {
  color: hsl(21 6% 65%);
}

.commisions_item_img_link {
  position: relative;
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

.commisions_item_background {
  position:relative;
  display: block;
  max-height: 80%;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--primary-colour);
}

.commisions_item_img {
  filter: drop-shadow(0px 0px 3px #222);
  position: relative;
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  margin: auto;
}

.commisions_item_section {
  text-align: center;
  display: block;
  color: var(--primary-colour);
}

.commisions_item_name {
  text-align: center;
  color: var(--primary-colour);
}
