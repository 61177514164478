.cart_list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.cart_item {
  z-index: 0;
  position: relative;
  padding: 0.5em;
  gap: 1em;
  display: flex;
  background-color: var(--secondary-colour);
  border-radius: 1em;
}

.cart_item img {
  border-radius: 1em;
  max-width: 15em;
  max-height: 15em;
  min-width: 15em;
  min-height: 15em;
  object-fit: contain;
  background-color: var(--primary-colour);
}

.cart_list_description {
  position:relative;
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;
  background-clip: text;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  background-image: linear-gradient(hsl(0, 0%, 0%, 0%),hsl(0, 0%, 0%, 0%),hsl(0, 0%, 0%, 0%), red);
  z-index: -1;
}

.cart_text_container {
  position: relative;
  z-index: 1;
  overflow-y: hidden;
  max-height: 15em;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  background-image: linear-gradient(hsl(0, 0%, 0%, 0%),hsl(0, 0%, 0%, 0%),hsl(0, 0%, 0%, 0%), red);
  /* background-image: linear-gradient(hsl(0, 0%, 0%, 0%),hsl(0, 0%, 0%, 0%),hsl(0, 0%, 0%, 0%), var(--secondary-colour)); */
}

