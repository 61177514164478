
.navbar {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  background-color: var(--secondary-colour);
  min-height: var(--header-hight);
  margin-top: 0;
  padding: 0.2em;
  box-shadow: 1;
}

.navbar-content {
  margin-left:auto;
  margin-right:auto;
  max-width: 80em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-top: 0;
  padding-block: 0.2em;
}

@media only screen and ( max-width: 1280px) {
  .navbar-content {
    padding-inline: 0.3em;
  }
}

.navbar-logo img {
  width: auto;
  /* max-height: calc(var(--header-hight) - 1em); */
  max-height: 2.3em;
  filter: drop-shadow( 0px 0px 2px hsl(0 0% 10%));
}

.navbar-list {
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: end;
  justify-content: flex-end;
}

.navbar-list li {
  display: inline;
  margin: 1em;
  font-size: 1.1em;
  text-shadow: 1px 1px hsl(0 0% 10%);
}

.navbar-link {
  max-height: 100%;
  text-decoration: none;
  color: var(--primary-colour);
  transition: color 200ms;
}

.navbar-selected {
  max-height: 100%;
  text-decoration: none;
  color: var(--primary-light);
  transition: color 200ms;
}

.navbar-link:hover {
  max-height: 100%;
  text-decoration: none;
  color: var(--primary-light);
}

.navbar_cart:hover .navbar_cart_dropdown {
  display: block;
}

.navbar_cart_dropdown:hover {
  display: block;
}

.navbar_cart_dropdown {
  z-index: 2;
  box-shadow: 0px 0px 1px 1px hsl(0 0% 20%);
  min-width:6em;
  display: none;
  position: absolute;
  float: right;
  background-color: var(--secondary-colour);
  padding: 0.2em;
}

.navbar_cart_dropdown img {
  border-radius: 0.2em;
  max-width: 2em;
  max-height: 2em;
  min-width: 2em;
  min-height: 2em;
  background-color: var(--primary-colour);
  object-fit: contain;
}

.navbar_cart_dropdown button {
  margin: 0.2em;
}

.navbar_cart_dropdown_section {
  border-radius: 0.2em;
  color: var(--primary-colour);
  display: flex;
  align-items: center;
  padding: 0.2em;
  gap: 0.2em;
}

.navbar_cart_dropdown_section:hover {
  background-color: var(--primary-light-trasparent);
}

.navbar_cart_dropdown_text {
  text-align: center;
}

.navbar_car_dropdown_buttons {
  display: flex;
  justify-content: space-between;
  padding: 0.2em;
}

.navbar_car_dropdown_buttons a {
  text-decoration: none;
  background-color: var(--primary-colour);
  color: var(--secondary-colour);
  border-radius: 0.2em;
  padding: 0.2em;
}

.navbar_car_dropdown_buttons a:hover {
  background-color: var(--secondary-colour);
  color: var(--primary-colour);
}

.navbar-extention {
  background-color: red;
}

@media only screen and (max-width: 1395px) {
  .navbar_cart_dropdown {
    right: 0.3em;
  }
}

@media only screen and (max-width: 430px) {
}

.navbar-burger-selected {
  color: var(--primary-colour) !important;
  background-color: var(--secondary-colour) !important;
}

.navbar-burger {
  background-color: var(--primary-colour);
  color: var(--secondary-colour);
  height: 2.3em;
  width: 2.3em;
  border-radius: 0.2em;
  text-align: center;
  font-weight: 900;
  font-size: 1.0em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-burger svg {
  font-size: 1.4em;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-dark);
  padding-block: 0.3em;
  box-shadow: inset 0px 0px 3px hsl(0 0% 5%);
  gap: 0.3em;
}
