
.about {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 100%;
  border-radius: 1em;
  position: relative;
  box-shadow: inset 0 0px 5px 1px hsl(0, 0%, 15%);
  background-color: var(--secondary-colour);
  padding: 1em;
}

@media only screen and ( max-width: 430px) {
  .about {
    border-radius: 0px;
  }
}

.about-img {
  max-width: 100%;
  max-height: 30em;
  /* min-height: 30em; */
  min-width: auto;
  object-fit: contain;
  margin: 1em;
  border-radius: 1em;
  box-shadow: 0px 0px 2px 1px hsl(0 0% 15%);
}

.about-text {
  margin: 1em;
  max-width: 30em;
  height: auto;
  width: auto;
}

.about-text p {

  color: var(--primary-colour);
}
